<template>
  <div class="bg-white pt-1">
    <div v-if="!hideTicket && !condensed" class="divider"></div>
    <div v-if="!hideTicket && !condensed" class="bg-white px-3 py-1 pb-5">
      <div class="mx-auto max-w-2xl text-center">
        <p class="text-grey-600 text-sm font-semibold">Got a Question?</p>
        <p class="mt-1 text-xs text-gray-600">Raise a support ticket and we'll get back to you.</p>
        <button class="btn-outline mt-4" @click.prevent="$router.push('/tickets-support/new')">
          New Support Ticket
        </button>
      </div>
    </div>
  </div>
  <footer class="black-bg py-4" :class="[!condensed ? '' : 'mt-20']">
    <div class="mx-auto max-w-screen-md">
      <div v-if="!condensed" class="grid grid-flow-col justify-stretch">
        <div v-for="item in items" :key="item" class="p-3">
          <ul>
            <li v-for="li in item" :key="li.link">
              <router-link :to="li.link" class="text-sm leading-8 text-white opacity-70">{{ li.label }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="mt-2 border-gray-600 p-3" :class="[!condensed ? 'border-t pb-3 pt-6' : 'pb-6 pt-3']">
        <img class="w-[160px]" src="~assets/images/footer-logo.png" alt="Veezu Partners" />
      </div>
    </div>
  </footer>
</template>

<script setup>
import _ from 'lodash'
import routes from '../server/routes'

const user = useUser()

const filteredRoutes = computed(() => {
  return routes
    .map((route) => {
      route.label === 'Plans & Invoices'
        ? (route.show = user.value?.driver.fleet?.invoicing_enabled)
        : (route.show = true)
      return route
    })
    .filter((route) => route.show)
})

const items = ref(_.chunk(filteredRoutes.value, 3))

defineProps({
  condensed: {
    type: Boolean,
    required: false,
    default: false,
  },
  hideTicket: {
    type: Boolean,
    required: false,
    default: false,
  },
})
</script>
